import "./icon-with-text.scss";
import React from "react";

const IconWithText = ({ icon, title, text }) => {
  return (
    <div className="icon-with-text">
      <div className="icon-with-text__icon-container">
        <img src={icon} alt="" className="icon-with-text__icon" />
      </div>
      <div className="icon-with-text__title">{title}</div>
      <div
        className="icon-with-text__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default IconWithText;
