import "styles/pages/page-location.scss";

import React, { useState } from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import IconWithText from "../components/IconWithText";
import ImageWithText from "../components/ImageWithText";
import Breadcrumbs from "../components/Breadcrumbs";

const PageLocation = ({ pageContext }) => {
	const pageLocation = pageContext.pageContent;
	const [chart, setChart] = useState("bangkok");

	return (
		<Layout headerColor="#54E0F7">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/location-header-bg.jpg").default}
			/>
			<Breadcrumbs currentLocationLabel="Informacje ogólne" />
			<section className="location-about">
				<div className="container">
					<h2 className="section-title">{pageLocation.infoTitle}</h2>
					<div className="row">
						{pageLocation.informations.map((node, index) => {
							return (
								<div className="col-md-4" key={index}>
									<IconWithText
										icon={node.icon?.sourceUrl}
										title={node.title}
										text={node.desc}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</section>
			<section className="location-population">
				<div className="container">
					<div className="row flex-md-row flex-column-reverse">
						<div className="col-md-6">
							<img
								src={pageLocation.populationImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 col-md-6 offset-xl-1">
							<div className="location-population__text-container">
								<h2 className="section-title">
									{pageLocation.populationTitle}
								</h2>
								<div
									className="location-population__text"
									dangerouslySetInnerHTML={{
										__html: pageLocation.populationDesc,
									}}
								></div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="location-geography">
				<div className="container">
					<div className="row">
						<div className="col-md-7">
							<h2 className="section-title">
								{pageLocation.positionTitle}
							</h2>
							<div className="location-geography__text-container">
								{pageLocation.positionInfo.map(
									(node, index) => {
										return (
											<TextWithSymbol
												key={index}
												desc={node.desc}
											/>
										);
									}
								)}
							</div>
						</div>
						<div className="col-md-5">
							<img
								src={
									require("assets/images/location-map.svg")
										.default
								}
								alt=""
								className="img-fluid location-geography__map"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="location-weather">
				<div className="container">
					<h2 className="section-title">
						{pageLocation.weatherTitle}
					</h2>
					<div className="row">
						<div className="col-lg-8 col-md-10 offset-lg-1">
							<div
								className="location-weather__text"
								dangerouslySetInnerHTML={{
									__html: pageLocation.weatherDesc,
								}}
							></div>
						</div>
					</div>
					<div className="row justify-content-center">
						{pageLocation.weatherInfo.map((node, index) => {
							return (
								<div className="col-xl-5 col-md-6" key={index}>
									<ImageWithText
										image={node.img?.sourceUrl}
										title={node.title}
										text={node.desc}
									/>
								</div>
							);
						})}
					</div>
				</div>
			</section>

			<section className="location-weather-chart">
				<div className="container">
					<div className="row align-items-center justify-content-center">
						<div className="col-md-4">
							<h4>Wybierz region i zobaczyć wykres pogodowy</h4>
						</div>
						<div className="col-md-6">
							<div className="location-weather-chart__nav">
								<button
									className={`button button--${
										chart === "bangkok" ? "yellow" : "gray"
									}`}
									onClick={() => setChart("bangkok")}
								>
									Bangkok
								</button>
								<button
									className={`button button--${
										chart === "kohSamui" ? "yellow" : "gray"
									}`}
									onClick={() => setChart("kohSamui")}
								>
									Koh Samui
								</button>
								<button
									className={`button button--${
										chart === "phuket" ? "yellow" : "gray"
									}`}
									onClick={() => setChart("phuket")}
								>
									Phuket
								</button>
							</div>
						</div>

						<div className="col-md-10">
							<img
								className="img-fluid location-weather-chart__legend"
								src={
									require("assets/images/location-weather-chart-legend.svg")
										.default
								}
								alt=""
							/>
							{chart === "bangkok" ? (
								<img
									className="img-fluid"
									src={
										require("assets/images/location-weather-chart-bangkok.svg")
											.default
									}
									alt=""
								/>
							) : chart === "kohSamui" ? (
								<img
									className="img-fluid"
									src={
										require("assets/images/location-weather-chart-kohSamui.svg")
											.default
									}
									alt=""
								/>
							) : chart === "phuket" ? (
								<img
									className="img-fluid"
									src={
										require("assets/images/location-weather-chart-phuket.svg")
											.default
									}
									alt=""
								/>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PageLocation;
